<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" /> 
        <CRow>
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start">
                <h6 class="mb-3 mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ $t('label.supplies') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4"  class="d-flex align-items-center justify-content-end">
                <CButton 
                        color="danger"
                        class="mr-1"
                        size="sm"
                        @click="onBtnPdf()"
                        >
                        <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                    </CButton>
                    <CButton
                        color="excel"
                        class="mr-1"
                        size="sm"
                        @click="onBtnExport(true)"
                    >
                        <CIcon name="cil-file-excel"/>&nbsp; XLSX
                    </CButton>
                    <CButton
                        color="watch"
                        size="sm"
                        @click="onBtnExport(false)"
                    >
                        <CIcon name="cil-file-excel"/>&nbsp; CSV
                    </CButton>
            </CCol>
        </CRow>
        <CRow class="mt-3">
            <label class="col-sm-11 col-lg-1 col-xl-1 text-right mt-2">{{$t('label.date')}}</label>
            <CCol sm="11" lg="2" xl="2" class="center-field px-1">
                <vue-datepicker  
                    :lang="$i18n.locale"
                    v-model="searchFilterDate.fromDay"
                    type="datetime"
                    format="DD/MM/YYYY HH:mm"
                    value-type="format"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    :show-second="false"
                    :clearable="false"
                    popup-class="vuedatapickerespecial"
                    :disabled-date="validateDateRange"
                    :editable="false"
                    :append-to-body="false"
                    class="col-sm-10 col-lg-12 col-xl-12 px-1 vue-datepicker-drive:focus"
                >
                    <template #icon-calendar>
                        <div style="display: none"></div>
                    </template>
                </vue-datepicker>
            </CCol>
            <label class="col-sm-11 col-lg-auto col-xl-auto text-right mt-2">-</label>
            <CCol sm="11" lg="2" xl="2" class="center-field px-1 mb-2">
                <vue-datepicker
                    :lang="$i18n.locale"
                    v-model="searchFilterDate.toDay"
                    type="datetime"
                    format="DD/MM/YYYY HH:mm"
                    value-type="format"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    :show-second="false"
                    :clearable="false"
                    popup-class="vuedatapickerespecial"
                    :disabled-date="validateDateRange"
                    :editable="false"
                    :append-to-body="false"
                    class="col-sm-10 col-lg-12 col-xl-12 px-1 vue-datepicker-drive:focus"
                >
                    <template #icon-calendar>
                        <div style="display: none"></div>
                    </template>
                </vue-datepicker>
            </CCol>
            <CCol class="col-sm-auto center-field" style="text-align: right;">
                <CButton
                    color="watch"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{content: $t('label.search')+' '+$t('label.dateRange'), placement: 'top-end'}" 
                    @click="getSupplyList()"
                    >
                    <CIcon name="cil-search" />
                </CButton>
                <CButton
                    color="wipe"
                    class="justify-content-end"
                    size="sm"
                    v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                    @click="refreshComponent()"
                    >
                    <CIcon name="cil-brush-alt" />
                </CButton>

            </CCol>
            <CCol sm="11" lg="5" xl="5" class="center-field p-0">
            </CCol>
        </CRow>
        <CRow class="my-2">
            <label class="col-sm-11 col-lg-1 col-xl-1 text-right mt-2">{{$t('label.filter')}}</label>
            <CCol sm="11" lg="2" xl="2" class="center-field px-2">
                <CInput
                    :placeholder="$t('label.enterSearch')"
                    v-model="textSearch"
                    required 
                >
                </CInput>
            </CCol>
            <CCol sm="11" lg="5" xl="5" class="center-field"></CCol>
            <CCol sm="11" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>
        </CRow>
        <CRow >
            <CCol sm="11" lg="12" xl="12" class="  mb-2">
                <div class="flex-grow-1 bd-highlight">
                    <ag-grid-vue
                        v-if="showGrid"
                        style="width: 100%; height: 100vh;"
                        class="ag-theme-alpine"
                        :gridOptions="gridOptions"
                        :defaultColDef="defaultColDef"
                        :localeText="localeText"
                        :columnDefs="columnDefs"
                        :rowData="formatedItems"
                        :suppressRowClickSelection="true"
                        :groupSelectsChildren="true"
                        :enableRangeSelection="true"
                        :pagination="true"
                        :paginationPageSize="paginationPageSize"
                        :paginationNumberFormatter="paginationNumberFormatter"
                        rowSelection="single"
                        @grid-ready="onGridReady"
                    >
                    </ag-grid-vue>
                </div>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { DateFormater } from '@/_helpers/funciones';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';

    //data
    function data() {
        return {
            textSearch:'',
            gridOptions: null,
            defaultColDef: null,
            gridApi: null,
            columnApi: null,
            paginationPageSize:null,
            paginationNumberFormatter: null,
            searchFilterDate:{
                fromDay:"",
                toDay:"",
            },
            VisitId: '',
            rows: [],
            loadingOverlay: false,
            previousDate: new Date(),
            laterDate: new Date(),
            nulo: '', 
            pageSize: '',
        }
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};

        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }

    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        
        if(rowData.length !== 0) {
            await this.getPdf(rowData,this.$t('label.supplies'));
            this.loadingOverlay = false;
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }    
        this.loadingOverlay = false;
    }

    async function onBtnExport(valor){
        this.loadingOverlay = true;

        let rowData = [];
        let CraneName = 'N/A';
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));

        let rowDataFormated = [];

        for (let i = 0; i < rowData.length; i++) {
            rowDataFormated.push({
                id:rowData[i].id,
                ClientName:rowData[i].ClientName,
                SupplyName:rowData[i].SupplyName,
                UnitMeasureName:rowData[i].UnitMeasureName,
                Quantity:rowData[i].QuantityOrigin,
                Observation: rowData[i].Observation,
                DateVisit:rowData[i].DateVisit,
                TransaLogin:rowData[i].TransaLogin,
            });
        }
        if(this.nulo!="null" && this.formatedItems.length !== 0){
            await this.getExcel(rowDataFormated,this.$t('label.supplies'),valor,CraneName, this.searchFilterDate.fromDay, this.searchFilterDate.toDay);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay= false;
    }        
  
    //computed
    function columnDefs(){
        let columnDefs = [
            {
                field: "id",
                suppressMovable: true,
                headerName: "#",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                lockVisible: true,
                lockPosition: true,
                maxWidth: 70,
                //pinned: 'left',
            },
            {
                field: "ClientName",
                headerName: this.$t('label.supplier'), 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            },
            {
                field: "SupplyName",
                headerName: this.$t('label.supplieName'), 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            },
            {
                field: "UnitMeasureName",
                headerName: this.$t('label.measureUnit'), 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            },
            {
                field: "Quantity",
                headerName: this.$t('label.quantity'), 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            },
            {
                field: "Observation",
                headerName: this.$t('label.observation'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            },
            {
                field: "TransaLogin",
                headerName: this.$t('label.user'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            },
            {
                field: "DateVisit",
                headerName: this.$t('label.date'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            },
        ]
        return columnDefs;
    }

       function formatedItems() {
        let computedItems = [];
        if(this.rows.length !== 0 && this.nulo!="null"){
            this.rows.map(function(item, index){
                computedItems.push({
                    id: item.Nro,
                    ClientName: item.ClientName? item.ClientName : '',
                    SupplyName: item.SupplyName? item.SupplyName : '',
                    UnitMeasureName: item.UnitMeasureName? item.UnitMeasureName : '',
                    Quantity: item.Quantity ? item.Quantity : '',
                    QuantityOrigin: item.Quantity ? formatMilDecimal(parseFloat(item.Quantity).toFixed(2)) : '0,00',
                    Observation: item.Observation? item.Observation : '',
                    DateVisit: item.DateVisit? DateFormater.formatDateWithSlash(item.DateVisit) : '',
                    TransaLogin: item.TransaLogin ? item.TransaLogin : '',                    
                })
            })
        }
        return computedItems.filter(item => {
            return item
        })
    }

    function getSupplyList(){
        this.loadingOverlay = true;
        let dateStart = '';
        let dateEnd = '';

        dateStart= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
        dateEnd= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);    

        let SupplyJson = {
            VisitId: this.Visit,
            DateStart: dateStart,
            DateEnd: dateEnd, 
        }

        this.$http.ejecutar('POST', 'VisitSupplyReport', SupplyJson, { root: 'SupplyJson' })
        .then(response => {
            this.rows = [...response.data.data];
            this.nulo = String(response.data.data[0].Json);
            this.loadingOverlay = false;
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        });
    }

    function validateDateRange(date) {
        const minDate = new Date(this.previousDate);     
        minDate.setHours(0, 0, 0, 0);

        const maxDate = new Date(this.laterDate);     
        maxDate.setHours(0, 0, 0, 0);

        return date < new Date(minDate.getTime()) || date > new Date(maxDate.getTime());
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function refreshComponent() {
        this.textSearch="";
        this.searchFilterDate.fromDay="";
        this.searchFilterDate.toDay="";
        this.pageSize=""; 
        this.gridApi.paginationSetPageSize(parseInt(50));
        await this.getDateRange();
        this.getSupplyList();
    }

        //rango de fechas
    function getDateRange(){
        let currentDate = new Date();

        if(this.itinerarySelected.Ata!=undefined && this.searchFilterDate.fromDay == ''){ 
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
        }

        if(this.itinerarySelected.Ata==undefined && this.searchFilterDate.fromDay == ''){
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
        }

        if(this.itinerarySelected.Atd!=undefined && this.searchFilterDate.toDay == ''){  
            this.searchFilterDate.toDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
            this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
        }

        if(this.itinerarySelected.Atd==undefined && this.searchFilterDate.toDay == ''){
            this.searchFilterDate.toDay=DateFormater.formatTimeZoneToDateTime(currentDate);
            this.laterDate = currentDate;
        }
    }
    //watch
    function textSearch(newValue) {
        this.gridApi.setQuickFilter(newValue);
    }

    export default {
        name:'index-insumos',
        props:['loading'],
        data,
        beforeMount,
        methods:{
            onBtnPdf,
            onBtnExport,
            onGridReady,
            getSupplyList,
            validateDateRange,
            onPageSizeChanged,
            refreshComponent,
            getDateRange,
        },
        mixins: [ReportesVisitas, AgGrid],
        computed:{
            columnDefs,
            formatedItems,
            ...mapState({
                Visit: state => state.visitas.VisitId,
                tabIndex: state => state.visitas.tabIndex,
                user: (state) => state.auth.user,
                itinerarySelected: state => state.visitas.itinerarySelected,
                dropItemAgenda: state => state.visitas.dropItemAgenda,
                dropItemReport: state => state.visitas.dropItemReport,
            })
        },
        directives: {
            uppercase: {
                bind(el, _, vnode) {
                el.addEventListener('input', (e) => {
                    e.target.value = e.target.value.toUpperCase()
                    vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
                });
                }
            },
        },
        watch:{
            textSearch,          
            dropItemReport: function (newValue) {
                if ((newValue==3) && (this.Visit!='')) {
                    this.VisitId = this.Visit;
                    this.refreshComponent();
                    this.$emit('child-refresh',true);
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        },
    }
</script>

<style lang="scss">
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-boton{
        margin-top: -1.5rem !important;    
        margin-right: 0.25rem !important;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>